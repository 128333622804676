

























import { Component, Vue } from 'vue-property-decorator'
import Main from '@/layouts/Main.vue'
import app from '@/store/modules/app'

@Component({
  components: { Main },
  metaInfo() {
    return {
      title: `${app.title} | Wedding Gallery`,
    }
  },
})
export default class WeddingGallery extends Vue {
  viewGallery(): void {
    window.open(
      'https://erikmaday.smugmug.com/2023/Katie-Erik-Wedding/',
      '_blank'
    )
  }

  uploadImage(): void {
    window.open(
      'https://erikmaday.smugmug.com/upload/mRHwQb/themadays',
      '_blank'
    )
  }
}
